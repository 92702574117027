<template>
  <q-btn round dense flat :ripple="false">
    <avatar size="24px" />
    <div
      v-if="!$q.platform.is.mobile"
      class="text-weight-medium text-capitalize q-ml-sm"
    >
      {{ firstName }}
      <q-icon name="expand_more" />
    </div>
    <q-menu
      class="q-mt-lg full-width default-rounded"
      max-width="300px"
      :offset="[5, 10]"
    >
      <div class="row no-wrap q-pa-sm">
        <div class="column">
          <q-list>
            <q-item clickable v-close-popup @click="signOut">
              <q-item-section avatar>
                <q-icon size="1.5em" name="logout" />
              </q-item-section>
              <q-item-section
                ><span class="text-weight-light">{{
                  $t("dashboard.card_user.exit")
                }}</span></q-item-section
              >
            </q-item>
          </q-list>
        </div>
      </div>
    </q-menu>
  </q-btn>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { useActions, useGetters } from "vuex-composition-helpers";
import Avatar from "@/modules/main/components/avatar";
import { computed } from "vue";

const { useState } = createNamespacedHelpers("auth");

export default {
  name: "card-user-blocked",

  components: { Avatar },

  setup() {
    const { partner } = useGetters({
        partner: "partner/getPartner",
      }),
      { user } = useState(["user"]),
      firstName = computed(() =>
        user.value ? user.value.nome.split(" ")[0] : "----"
      ),
      { signOutAction } = useActions({
        signOutAction: "auth/signOutAction",
      });

    const signOut = () => signOutAction().finally(() => location.reload());

    return {
      user,
      partner,
      firstName,
      signOut,
    };
  },
};
</script>
