<template>
  <div class="q-mt-lg bg-grey-4">
    <q-layout view="lHh Lpr lff">
      <q-header bordered class="bg-white">
        <q-toolbar class="q-pr-lg row text-cs-black">
          <div class="row justify-center width-fit-content-flex">
            <q-item>
              <q-img
                fit="contain"
                :width="$q.platform.is.mobile ? '60px' : '100px'"
                :src="logo"
              />
            </q-item>
          </div>
          <div class="q-gutter-sm row items-center no-wrap">
            <template v-if="isFlipnetCompany || getSwitchCompanies()">
              <list-my-companies />
              <q-separator vertical />
            </template>
            <card-user-block />
          </div>
        </q-toolbar>
      </q-header>
      <q-page-container
        v-if="layoutConfig"
        :style="`background-color: ${layoutConfig.body_background}`"
      >
        <q-page padding :style-fn="myTweak">
          <router-view></router-view>
        </q-page>
        <footer-component />
      </q-page-container>
    </q-layout>
  </div>
</template>

<script setup>
import { getSwitchCompanies } from "@/shared/helpers/switchCompaniesHelper";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import FooterComponent from "../components/FooterComponent.vue";
import ListMyCompanies from "../views/list-my-companies";
import cardUserBlock from "../views/card-user-blocked";
import helpers from "@/shared/helpers";
import { onMounted, ref } from "vue";

const { getCustomLayout } = helpers.customLayout;

const partnerNamespace = createNamespacedHelpers("partner");

const layoutConfig = ref(null);

document.body.classList.add("theme-light-dark");

const { logo, isFlipnetCompany } = partnerNamespace.useGetters({
  logo: "getPartnerParentLogo",
  isFlipnetCompany: "isFlipnetCompany",
});
const myTweak = () => ({ minHeight: "65vh" });

onMounted(() => {
  layoutConfig.value = getCustomLayout();
});
</script>
